import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import fetchImageUrl from "../functions/FetchFileWithToken";
import { Modal } from "rsuite";
import Styles from "./_user.module.css";
import linkifyHtml from "linkify-html";

const SharedMessage = () => {
  const location = useLocation(); // Access location object
  const { likeShareList } = location.state || {}; // Destructure your passed data
  console.log(likeShareList);
  let Token = sessionStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleOpenModal = type => {
    console.log(type);
    setSelected(type);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelected([]);
  };

  //Like-------------------------------
  const [openModalLike, setOpenModalLike] = useState(false);

  const handleOpenModalLike = type => {
    console.log(type);
    let data = type.student_action.filter(ele => {
      return ele.like > 0;
    });
    console.log(data);
    setSelected(data);
    setOpenModalLike(true);
  };
  const handleCloseModalLike = () => {
    setOpenModalLike(false);
    setSelected([]);
  };
  //Dislike-------------------------------
  const [openModalDislike, setOpenModalDislike] = useState(false);

  const handleOpenModalDislike = type => {
    console.log(type);
    let data = type.student_action.filter(ele => {
      return ele.dislike > 0;
    });
    console.log(data);
    setSelected(data);
    setOpenModalDislike(true);
  };
  const handleCloseModalDislike = () => {
    setOpenModalDislike(false);
    setSelected([]);
  };
  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  //Share-------------------------------
  const [openModalShare, setOpenModalShare] = useState(false);

  const handleOpenModalShare = type => {
    console.log(type);
    let data = type.student_action.filter(ele => {
      return ele.share > 0;
    });
    console.log(data);
    setSelected(data);
    setOpenModalShare(true);
  };
  const handleCloseModalShare = () => {
    setOpenModalShare(false);
    setSelected([]);
  };
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        likeShareList.map(async message => {
          if (message?.message.files) {
            const fileData = await fetchImageUrl(
              message?.message.files[0]?.path,
              Token
            );
            if (fileData) {
              newFileUrls[message?.message.files[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, []);

  return (
    <div>
      {/* Modal for displaying names */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}> Total Number of Students</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.student_action?.length > 0 ? (
            selected?.student_action?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for Likes displaying names */}
      <Modal open={openModalLike} onClose={handleCloseModalLike}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students liked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for Dislikes displaying names */}
      <Modal open={openModalDislike} onClose={handleCloseModalDislike}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students disliked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for shared displaying names */}
      <Modal open={openModalShare} onClose={handleCloseModalShare}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students disliked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username} - <span>{name.share}</span>
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>{" "}
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "#333",
            // mb: 2,
            display: "flex",
            justifyContent: "center",
            color: "rgb(245, 124, 0)",
          }}
        >
          Shared Message List
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: "620px" }}>
        <Table stickyHeader aria-label="shared messages table">
          <TableHead>
            <TableRow sx={{ background: "gray" }}>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Message
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Total Students
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Like
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Dislike
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Share
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {likeShareList?.map((ele, ind) => (
              <TableRow key={ind}>
                <TableCell>
                  <Typography variant="body1">
                    {ele?.message?.files ? (
                      ele?.message?.files[0].mimetype === "image/jpg" ||
                      ele?.message?.files[0].mimetype === "image/jpeg" ? (
                        <>
                          <img
                            src={imageUrls[ele?.message?.files[0]?.path]?.url}
                            alt=""
                            style={{
                              height: "150px",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            // onClick={() =>
                            //   handleImage(
                            //     imageUrls[ele?.message?.files[0]?.path]?.url
                            //   )
                            // }
                          />
                        </>
                      ) : (
                        ele?.message?.files[0].filename
                      )
                    ) : ele?.message?.text ? (
                      ele?.message?.text && ele?.html ? (
                        <pre
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtmlContent(
                              ele?.message?.text || ""
                            ),
                          }}
                          className={Styles.textEditStyle}
                        />
                      ) : (
                        ele?.message?.text?.slice(0, 85)
                      )
                    ) : (
                      ""
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModal(ele)}
                  >
                    {ele?.total_students}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModalLike(ele)}
                  >
                    {ele?.like}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModalDislike(ele)}
                  >
                    {ele?.dislike}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModalShare(ele)}
                  >
                    {ele?.share}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SharedMessage;
