import React, { Fragment, useEffect, useRef, useState } from "react";
import Styles from "./_help.module.css";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import UserMessage from "./UserMessage";
import { AiFillDelete } from "react-icons/ai";
import { ButtonToolbar, Modal, Tooltip, Whisper } from "rsuite";
import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import { ImAttachment } from "react-icons/im";
import FileUpload from "./FileUpload";
import { socket } from "../../ioContext/socketIo";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import dateFormat from "dateformat";
import handleError from "../Users/ErrorHandler";
import fetchImageUrl from "../functions/FetchFileWithToken";
import wavEncoder from "wav-encoder";
// text editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Box, Button, Divider, Modal as ModalMui } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import RemoveHtmlTag from "../functions/RemoveHtmlTag";
import { toast } from "react-toastify";
const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};
const ChatComp = ({ existingTitle, particularStudent }) => {
  let scrollRef = useRef();
  let navigate = useNavigate();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  const [number, SetNumber] = useState(0);

  const [newMessage, setNewMessage] = useState("");
  let [loadingmsg, setLoadingMsg] = useState(false);

  let [message, setMessage] = useState([]);
  const [queue, setQueue] = useState(
    JSON.parse(localStorage.getItem("messageQueue")) || []
  );
  const [pastedImage, setPastedImage] = useState(null);
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);

  let d = Date.now();

  let [loading, setLoading] = useState(false);

  let [msgScroll, setMsgScroll] = useState(0);
  let [messageCount, setMessageCount] = useState(0);
  let [lengthmsg, setLengthMsg] = useState(0);
  let HandleLoadMessages = () => {
    setMsgScroll(msgScroll + lengthmsg);
  };

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  const [isIconDisabled, setIsIconDisabled] = useState(false);
  const [open, setOpen] = React.useState(false);

  // const [GroupUsers, setGroupUsers] = useState([]);

  // text Editor ------------------------------
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);
  const handleEditorChange = state => {
    setEditorState(state);
  };

  // text Editor modal
  const [openEditor, setOpenEditor] = React.useState(false);

  const handleOpenEditor = () => {
    const contentState = ContentState.createFromText(newMessage);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setOpenEditor(true);
    setShowEditor(!showEditor);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
    setShowEditor(false);
    setEditorState(EditorState.createEmpty());
  };

  // que message

  // Listen for network changes and attempt to send queued messages
  useEffect(() => {
    const handleOnline = () => {
      if (user != null && queue.length > 0) {
        sendQueuedMessages();
      }
    };
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("online", handleOnline);
    };
  }, [queue]);

  //reply
  const [reply, setReply] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const handleMessageClick = (clickedMessage, index, isMessageSelected) => {
    const existingIndex = selectedMessages.findIndex(
      msg =>
        (msg.messages.text === clickedMessage.messages.text &&
          msg.index === clickedMessage.index) ||
        (msg.messages.flies === clickedMessage?.messages?.flies &&
          msg.index === clickedMessage.index)
    );

    const isMessageAlreadySelected = existingIndex !== -1;
    const hasReachedMaxSelection = selectedMessages.length === 5;

    if (!isMessageAlreadySelected && selectedMessages.length < 5) {
      setSelectedMessages([...selectedMessages, clickedMessage]);
    } else if (isMessageAlreadySelected) {
      const updatedMessages = selectedMessages.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedMessages(updatedMessages);
    } else if (selectedMessages.length === 5) {
      // Alert message when the selection limit is reached or duplicate is clicked
      alert(`You can select only 5 unique messages.`);
    }
  };

  let forwardmsg = [];
  let [selected, setSelected] = useState(false);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClose = () => {
    // setImagePreview(null);
    setOpen(false);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);

  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
      } catch (error) {
        alert("Error accessing microphone");
        setIsRecording(false);
        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;

      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };

  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);
    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive(prevPlaybackState => !prevPlaybackState);
    setIsIconDisabled(true); // Disable the icon when clicked
    // Clear recorded chunks when playback is triggered
  };

  useEffect(() => {
    if (recordedAudio) {
      storeAudioLocally(recordedAudio);
    }
  }, [recordedAudio]);
  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });
    return URL.createObjectURL(audioBlob);
  };

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
      // className={!stop ? `${Styles.AudioNot}` : `${Styles.Audio}`}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const storeAudioLocally = audioBlob => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };

  let HandleAudio = async e => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = arrayBuffer => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );
      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };
          const options = {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );
          let filesData = res?.data?.files;

          socket.emit(
            "helpGroup",
            {
              requestId: existingTitle.requestId,
              files: filesData,
              senderId: user._id,
              senderName: user.username,
              receiverId: particularStudent?.studentId,
              role: user?.role1,
              section: existingTitle.section,
              title: existingTitle.title,
            },
            function callback(msg) {
              setMessage([...message, msg]);
              setIsPlaybackActive(false);
              setIsAudioVisible(false);
              setRecordedChunks([]);
              setIsPlaying(false);
            }
          );
          setIsAudioVisible(false);
          setRecordedChunks([]);
          setIsPlaying(false);
        } catch (error) {}
      } else {
        //("type msg")
      }
      // return file;
    }
    return null;
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setIsAudioVisible(false);
    setRecordedChunks([]);
    setIsPlaying(false);
  };

  useEffect(() => {
    async function fetchlist() {
      try {
        setLoadingMsg(true);
        socket.emit("chatwith", {
          senderId: user?._id,
          role: "qtalkHelp",
          receiverId: existingTitle.requestId,
          device: "web",
        });
        let payload = {
          title: existingTitle.title,
          requestId: existingTitle.requestId,
          scroll: msgScroll,
        };

        let x = await AxiosInstance.post(`/help/getChatTitle`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        });
        x?.data?.chat?.reverse();

        let data = x?.data?.chat;
        setLengthMsg(data.length);
        setMessageCount(x?.data?.msgcount);
        if (x?.data?.chat?.length !== 0 && msgScroll > 0) {
          setMessage([...data, ...message]);
        } else if (x?.data?.chat?.length !== 0 && msgScroll <= 0) {
          setMessage(data);
        }
        setLoadingMsg(false);
      } catch (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK" || error.message === "Network Error") {
          toast.error(
            "Internet connection lost. Please check your connection and try again."
          );
        }
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
        setLoadingMsg(false);
      }
    }
    fetchlist();
    // }
  }, [existingTitle, particularStudent, msgScroll]);

  useEffect(() => {
    socket.on("getHelpMsg", data => {
      if (data.senderId !== user._id) {
        setArrivalMessage(data);
      }
    });
  }, [existingTitle, particularStudent]);

  useEffect(() => {
    if (arrivalMessage) {
      setMessage(prev => [...prev, arrivalMessage]);
    }
  }, [particularStudent, arrivalMessage]);

  useEffect(() => {
    if (arrivalMessageFile.length > 0) {
      setMessage(prevMessages => prevMessages.concat(arrivalMessageFile));

      setArrivalMessageFile([]);
    }
  }, [particularStudent, arrivalMessageFile]);

  // const sendQueuedMessages = () => {
  //   if (!navigator.onLine) return;
  //   let newQueue = [...queue]; // Create a copy of the current queue
  //   queue.forEach((msg, index) => {
  //     if (navigator.onLine) {
  //       socket.emit("helpGroup", msg, data => {
  //         // setMessage([...message, data]);
  //       });

  //       // Remove the successfully sent message from the queue
  //       newQueue = newQueue.filter((_, i) => i !== index);
  //       // Once all messages are processed, update the queue
  //       if (index === queue.length - 1) {
  //         setQueue(newQueue);
  //         localStorage.setItem("messageQueue", JSON.stringify(newQueue));
  //       }
  //     }
  //   });
  // };
  const sendQueuedMessages = () => {
    if (!navigator.onLine) return;

    // Wrap socket emit in a promise
    const sendMessageWithSocket = msg => {
      return new Promise((resolve, reject) => {
        socket.emit("helpGroup", msg, data => {
          if (data) {
            // setMessage(prevMessages => [...prevMessages, data]);
            resolve(); // Resolve promise if message is sent
          } else {
            reject(); // Reject if there's an error
          }
        });
      });
    };

    // Map over queue to create an array of promises
    const promises = queue.map(msg => sendMessageWithSocket(msg));

    // Wait for all messages to be sent
    Promise.all(promises)
      .then(() => {
        // Once all messages are sent, clear the queue
        setQueue([]);
        localStorage.setItem("messageQueue", JSON.stringify([]));
      })
      .catch(err => {
        console.error("Error sending queued messages:", err);
      });
  };

  // console.log(message);
  console.log("lll");
  let handelSubmit = async e => {
    e?.preventDefault();

    if (newMessage.trim() !== "") {
      let formattedMessage;
      let instantMsg;
      if (reply) {
        formattedMessage = {
          senderId: user._id,
          senderName: user.username,
          receiverId: particularStudent?.studentId,
          text: newMessage,
          role: user?.role1,
          section: existingTitle.section,
          requestId: existingTitle.requestId,
          title: existingTitle.title,
          device: "web",
          status: null,
          replayback: replyBack,
        };
        const messageText = selectedMessages[0]?.message?.text;
        const htmlContent = selectedMessages[0]?.html === true;
        const replyBack = {
          message: messageText
            ? htmlContent
              ? await RemoveHtmlTag(messageText)
              : messageText
            : "",
          file: selectedMessages[0]?.message?.files || "",
        };

        if (navigator.onLine) {
          socket?.emit(
            "helpGroup",
            {
              senderId: user._id,
              senderName: user.username,
              receiverId: particularStudent?.studentId,
              text: newMessage,
              role: user?.role1,
              section: existingTitle.section,
              requestId: existingTitle.requestId,
              title: existingTitle.title,
              device: "web",
              status: null,
              replayback: replyBack,
            },
            function callback(msg) {
              setMessage([...message, msg]);
              setReply(false);
              setSelectedMessages([]);
            }
          );
        }
      } else {
        formattedMessage = {
          senderId: user._id,
          senderName: user.username,
          receiverId: particularStudent?.studentId,
          text: newMessage,
          role: user?.role1,
          section: existingTitle.section,
          requestId: existingTitle.requestId,
          title: existingTitle.title,
          device: "web",
          status: null,
        };

        if (navigator.onLine) {
          socket?.emit(
            "helpGroup",
            {
              senderId: user._id,
              senderName: user.username,
              receiverId: particularStudent?.studentId,
              text: newMessage,
              role: user?.role1,
              section: existingTitle.section,
              requestId: existingTitle.requestId,
              title: existingTitle.title,
              device: "web",
              status: null,
            },
            function callback(msg) {
              setMessage([...message, msg]);
            }
          );
        } else {
          instantMsg = {
            message: { text: newMessage },
            senderId: user._id,
            senderName: user.username,
            requestId: particularStudent?.studentId,
            online: true,
          };
        }
      }
      if (navigator.onLine) {
        // console.log("Message sent:", formattedMessage);
      } else {
        const newQueue = [...queue, formattedMessage];
        setQueue(newQueue);
        localStorage.setItem("messageQueue", JSON.stringify(newQueue));
        setMessage([...message, instantMsg]);
      }
    }

    try {
      setNewMessage("");
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  let handleEditorSend = e => {
    const contentState = editorState.getCurrentContent();
    // Check if the content is empty
    const isEmpty =
      !contentState.hasText() || contentState.getPlainText().trim() === "";
    if (!isEmpty) {
      const contentState = editorState.getCurrentContent();

      const rawContentState = convertToRaw(contentState);

      // let data = JSON.stringify(rawContentState);
      let content = draftToHtml(rawContentState);
      setNewMessage(content);
      socket?.emit(
        "helpGroup",
        {
          senderId: user._id,
          senderName: user.username,
          receiverId: particularStudent?.studentId,
          text: content,
          html: true,
          role: user?.role1,
          section: existingTitle?.section,
          requestId: existingTitle?.requestId,
          title: existingTitle?.title,
          device: "web",
          status: null,
        },
        function callback(msg) {
          setMessage([...message, msg]);
          setLoading(false);
          // if (msg.read === false) {
          //   msg.tick = false;
          //   setMessage([...message, msg]);
          //   setLoading(false);
          // } else {
          //   setMessage([...message, msg]);
          //   setLoading(false);
          // }
        }
      );
      setEditorState(EditorState.createEmpty());
      setOpenEditor(false);
      setShowEditor(false);
    }
    setNewMessage("");
  };

  let handelSubmitButton = e => {
    handelSubmit();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };

  let selectedmsg = data => {
    let aaa = forwardmsg?.map(x => x.index);
    const index = data.index;
    if (aaa.includes(index)) {
      for (let i = 0; i < forwardmsg.length; i++) {
        if (forwardmsg[i].index === index) {
          forwardmsg.splice(i, 1);
          i--; // decrement i to account for removed element
        }
      }
    } else {
      if (forwardmsg.length === 5 || forwardmsg.length > 5) {
        alert("you can only send 5 messages at a time");
      } else {
        forwardmsg.push(data);
      }
    }
  };

  let Loadmore = () => {
    SetNumber(number + 50);
  };

  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard
    if (pastedText) {
      setNewMessage(pastedText);
    }

    // Handle image paste
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setOpen(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);

        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };

  const [imageUrls, setImageUrls] = useState({});
  const [imageUrlsReply, setImageUrlsReply] = useState({});
  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        message.map(async message => {
          if (message?.message?.files !== undefined) {
            const fileData = await fetchImageUrl(
              message?.message?.files[0]?.path,
              user?.TOKEN
            );
            if (fileData) {
              newFileUrls[message?.message?.files[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [message]);
  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrlsReply = {};

      await Promise.all(
        message.map(async message => {
          if (
            message?.replayback &&
            Object.keys(message?.replayback).length > 0 &&
            message?.replayback?.file !== "" &&
            message?.replayback?.file != undefined
          ) {
            const fileData = await fetchImageUrl(
              message?.replayback?.file[0]?.path,
              user?.TOKEN
            );

            if (fileData) {
              newFileUrlsReply[message?.replayback?.file[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrlsReply(prevUrls => ({ ...prevUrls, ...newFileUrlsReply }));
    };

    fetchUrls();
  }, [message]);
  let CancelReply = () => {
    setReply(false);
    setSelectedMessages([]);
  };

  return (
    <section style={{ width: "72%" }}>
      <ModalMui open={openEditor}>
        <Box sx={styleModal1} position="relative">
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>Text Editor</h4>
          <Divider />
          <br />
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={handleCloseEditor}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Box id="modal-description" sx={{ mt: 2 }}>
            <Editor
              editorState={editorState}
              wrapperClassName="chatEditorWrapper"
              editorClassName="chatEditor"
              onEditorStateChange={handleEditorChange}
              placeholder="Write something..."
              toolbar={{
                options: ["inline", "colorPicker", "emoji", "history"],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                colorPicker: {
                  colors: [
                    "#000000",
                    "#ffffff",
                    "#ff0000",
                    "#00ff00",
                    "#0000ff",
                    "#FFFF00",
                  ],
                },
              }}
            />
          </Box>

          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "flex-end", mt: 2 }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseEditor}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleEditorSend}
            >
              Send
            </Button>
          </Box>
        </Box>
      </ModalMui>

      <main
        style={{
          width: "100%",
          overflowY: "auto",
          height: "93%",
          background: "#fff",
        }}
      >
        <div className={Styles.chat}>
          {messageCount != msgScroll + lengthmsg && (
            <div
              onClick={HandleLoadMessages}
              // className={Styles.Spinner}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                viewBox="0 0 20 20"
              >
                <g fill="#555">
                  <path d="M13.937 4.211a1 1 0 0 1-1.126 1.653A5 5 0 1 0 15 10a1 1 0 1 1 2 0a7 7 0 1 1-3.063-5.789"></path>
                  <path d="M13.539 12.506a1 1 0 1 1-1.078-1.685l3.482-2.227a1 1 0 0 1 1.077 1.685z"></path>
                  <path d="M18.903 12.41a1 1 0 0 1-1.826.815l-1.508-3.38a1 1 0 1 1 1.826-.815z"></path>
                </g>
              </svg>
            </div>
          )}
          {/* <!-- Messages --> */}
          {loadingmsg === true ? (
            <div className={Styles.loader}>
              {" "}
              <ThreeDots color="#00BFFF" height={80} width={80} />
            </div>
          ) : message?.length > 0 ? (
            message?.map((ele, ind) => (
              <Fragment key={ind}>
                <UserMessage
                  index={ind}
                  messages={ele}
                  own={ele?.senderId === user?._id}
                  particularStudent={particularStudent}
                  loadingmsg={loadingmsg}
                  selectedmsg={selectedmsg}
                  forwardmsg={forwardmsg}
                  setSelected={setSelected}
                  selected={selected}
                  imageUrls={imageUrls}
                  setImageUrls={setImageUrls}
                  imageUrlsReply={imageUrlsReply}
                  setImageUrlsReply={setImageUrlsReply}
                  setReply={setReply}
                  reply={reply}
                  selectedMessages={selectedMessages}
                  onClick={handleMessageClick}
                  setSelectedMessages={setSelectedMessages}
                />
              </Fragment>
            ))
          ) : (
            <span className={Styles.noConversation}>
              <p style={{ color: "#f16f1d", textAlign: "center" }}>
                No Messages
              </p>
            </span>
          )}
        </div>
        <div ref={scrollRef} style={{ visibility: "hidden" }}>
          top
        </div>
      </main>
      <form onSubmit={handelSubmit} className={Styles.FormMessage}>
        {reply && (
          <div className={Styles.replyBlock}>
            <div className={Styles.StatusText1}>
              {" "}
              {selectedMessages && (
                <>
                  {/* <div>Reply</div> */}
                  <div className={Styles.copiedText}>
                    {selectedMessages[0]?.message?.text
                      ? selectedMessages[0]?.message?.text?.slice(0, 180)
                      : selectedMessages[0]?.message?.files[0]?.filename}
                  </div>
                  <div
                    className={Styles.CrossMark}
                    onClick={() => CancelReply()}
                  >
                    X
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className={Styles.messageinput}>
          {/* <!-- Message Input Field --> */}
          <input
            type="text"
            placeholder="Type your message"
            value={newMessage}
            onChange={e => {
              setNewMessage(e.target.value);
            }}
            onKeyPress={handleKeyPress}
            autoFocus
            onPaste={handlePaste}
          />
          <aside
            style={{
              position: "relative",
              margin: "1%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div onClick={handleOpenEditor}>
              <Whisper followCursor speaker={<Tooltip>{"text edit"}</Tooltip>}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="#475569"
                      d="M9.5 2c.502 0 .956.3 1.152.763l5.072 11.989l-1.908 1.907L12.48 13.5H6.52l-1.37 3.237a1.25 1.25 0 0 1-2.302-.974l5.5-13A1.25 1.25 0 0 1 9.5 2m1.922 9L9.5 6.458L7.58 11zm9.838.328a4.536 4.536 0 0 1 6.414 6.415l-9.418 9.417a4 4 0 0 1-1.73 1.018l-6.253 1.787a1 1 0 0 1-1.236-1.236l1.785-6.251a4 4 0 0 1 1.018-1.73z"
                    ></path>
                  </svg>
                </span>
              </Whisper>
            </div>
          </aside>
          <aside
            style={{ position: "relative" }}
            className={Styles.UploadfileContainer}
          >
            <>
              {isRecording ? (
                ""
              ) : stop ? (
                ""
              ) : (
                <>
                  <ButtonToolbar>
                    <ImAttachment onClick={handleOpen}></ImAttachment>
                  </ButtonToolbar>
                </>
              )}

              <Modal keyboard={false} open={open} onClose={handleClose}>
                <Modal.Header>
                  <Modal.Title>Send File</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <FileUpload
                    PStudent={particularStudent}
                    setMessages={setMessage}
                    messages={message}
                    setOpen={setOpen}
                    setArrivalMessage={setArrivalMessage}
                    setArrivalMessageFile={setArrivalMessageFile}
                    existingTitle={existingTitle}
                    selectedMessages={selectedMessages}
                    reply={reply}
                    setReply={setReply}
                    setSelectedMessages={setSelectedMessages}
                    pastedImage={pastedImage}
                    setPastedImage={setPastedImage}
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </>
          </aside>
          <aside className={Styles.AudioBlock}>
            <div>
              {!stop && !isRecording && !isPlaying && (
                <Whisper followCursor speaker={<Tooltip>{"Start"}</Tooltip>}>
                  <main
                    className={Styles.StartAudio}
                    onClick={handleStartRecording}
                    disabled={isRecording}
                  >
                    <span>
                      {/* start */}
                      <BsFillMicFill />
                    </span>
                  </main>
                </Whisper>
              )}
              <div
                className={`${Styles.recordingAnimation} ${
                  isRecording ? Styles.active : ""
                }`}
              ></div>
              {isRecording && (
                <main
                  className={Styles.StopAudio}
                  onClick={handleStopRecording}
                  disabled={!isRecording}
                >
                  <span>
                    {/* stop */}
                    <BsFillMicMuteFill />
                  </span>
                </main>
              )}
              {stop && (
                <Whisper followCursor speaker={<Tooltip>{"Play"}</Tooltip>}>
                  <main
                    className={Styles.PlayAudio}
                    onClick={handlePlayButtonClick}
                    disabled={isRecording}
                  >
                    <span>
                      {/* play */}
                      <BsFillPlayCircleFill />
                    </span>
                  </main>
                </Whisper>
              )}
              {/* {stop &&<p>{recordingStatus}</p>} */}
              {audioPlayer}
            </div>
          </aside>
          {stop && (
            <div className={Styles.SendAudioBlock}>
              <Whisper followCursor speaker={<Tooltip>{"Delete"}</Tooltip>}>
                <span
                  onClick={handleDeleteButtonClick}
                  className={Styles.DeleteAudio}
                >
                  <AiFillDelete />
                </span>
              </Whisper>
              <span onClick={HandleAudio} className={Styles.SendAudio}>
                send
                {/* <AiOutlineSend /> */}
              </span>
            </div>
          )}
          {!stop && (
            <div>
              {loading || isRecording ? (
                <button
                  className="chatSubmitButton"
                  disabled
                  onClick={handelSubmitButton}
                >
                  <div className={Styles.loader}>
                    <RotatingLines
                      strokeColor="#f16f1d"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </div>
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className={Styles.sendbutton}
                    onClick={handelSubmitButton}
                  >
                    Send
                  </button>
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    className={Styles.sendbutton}
                    onClick={handelSubmitButton}
                  >
                    Send
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </form>
    </section>
  );
};

export default ChatComp;
