import React, { useRef } from "react";
import Moment from "react-moment";
import Styles from "./_chat.module.css";
import { IoCheckmarkDoneSharp, IoSendSharp } from "react-icons/io5";
import { FcCheckmark } from "react-icons/fc";
import { socket } from "../../../ioContext/socketIo";
import Linkify from "react-linkify";
import linkifyHtml from "linkify-html";
import "./styles.css";
import { Button, Dropdown, IconButton, Modal } from "rsuite";
import { useState } from "react";
import useDownloader from "react-use-downloader";
import AxiosInstance from "../../../api/AxiosInstance";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import handleError from "../../Users/ErrorHandler";
import fetchImageUrl from "../../functions/FetchFileWithToken";
import {
  Modal as MuiModal,
  Box,
  IconButton as MuiIconButton,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import Badge from "@mui/material/Badge";

const modalStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
  minHeight: "min-content",
  maxHeight: "90vh",
  overflowY: "auto",
};
const iconContainerStyle = {
  display: "flex",
  justifyContent: "space-around",
  margin: "20px 0",
};

const StuMessage = ({
  message,
  own,
  particularStudent,
  onClick,
  setReply,
  reply,
  selectedMessages,
  index,
  setSelectedMessages,
  likeShare,
  setLikeShare,
}) => {
  let navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const { download, isInProgress } = useDownloader();
  let [pollData, setPollData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [maindata, setMainData] = useState([]);
  let [viewPollData, setViewPollData] = useState([]);
  let [viewOptions, setViewOptions] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [openPoll, setOpenPoll] = React.useState(false);
  const [openPollView, setOpenPollView] = React.useState(false);
  const [backgroundColor1, setBackgroundColor1] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const imgRef = useRef(null);
  const userToken = sessionStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareMsg, setShareMsg] = useState([]);
  const handleDownload = () => {
    const fileUrl = message?.messages?.files[0].path; // Assuming `fileUrl` is the URL of the file
    const fileName = message?.messages?.files[0].filename; // Assuming `fileName` is the name of the file

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => saveAs(blob, fileName))
      .catch(error => console.error("Error downloading file:", error));
  };

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("share_like_status", data => {
  //       console.log(data);
  //       const updatedArray = likeShare.map(item => {
  //         console.log(item?.post_id);
  //         console.log(data?.post_id);
  //         console.log(item?.post_id === data?.post_id);
  //         if (item?.post_id === data?.post_id) {
  //           console.log("inside");
  //           // Update the likes for the matched object
  //           return {
  //             ...item,
  //             like: item.like + 1,
  //           };
  //         }
  //         // Return other objects unchanged
  //         return item;
  //       });

  //       setLikeShare(updatedArray);
  //     });
  //   }
  // }, [socket]);
  useEffect(() => {
    if (socket) {
      socket.on("share_like_status", data => {
        // console.log(data);
        const updatedArray = likeShare?.map(item => {
          if (item?.post_id === data?.post_id) {
            return {
              ...item,
              ...data,
            };
          }
          return item;
        });
        // console.log(updatedArray);
        // Set the updated array to state
        setLikeShare(updatedArray);
      });
    }
  }, [socket, likeShare]);

  let likeCount = id => {
    let likes = likeShare?.filter(ele => ele?.post_id == id);
    if (likes?.length > 0) {
      return likes[0]?.like;
    } else {
      return null;
    }
  };

  const count = likeCount(message?.share?.usershare_id);

  let dislikeCount = id => {
    let likes = likeShare?.filter(ele => ele?.post_id == id);
    if (likes?.length > 0) {
      return likes[0]?.dislike;
    } else {
      return null;
    }
  };
  const countDislikes = dislikeCount(message?.share?.usershare_id);
  let shareCount = id => {
    let likes = likeShare?.filter(ele => ele?.post_id == id);
    if (likes?.length > 0) {
      return likes[0]?.share;
    } else {
      return null;
    }
  };
  const countShares = shareCount(message?.share?.usershare_id);
  // State to track if the button is "liked"
  const [isLiked, setIsLiked] = useState(false);
  const [isDisLiked, setIsDisLiked] = useState(false);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  useEffect(() => {
    const isMessageSelected = selectedMessages.some(
      msg =>
        msg?.messages?.text === message?.messages?.text && msg?.index === index
    );

    setBackgroundColor1(isMessageSelected ? "rgb(0 0 0 / 12%)" : "");
  }, [selectedMessages, message, index]);

  const handleClick = () => {
    const isMessageSelected = selectedMessages.some(
      msg => msg.messages.text === message.messages.text && msg.index === index
    );

    if (selectedMessages?.length < 1) {
      setBackgroundColor1(backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : "");
      const modifiedMessage = { ...message, index, isMessageSelected };
      onClick(modifiedMessage);
    } else if (reply === false && selectedMessages?.length === 1) {
      setBackgroundColor1(backgroundColor1 === "");
      setSelectedMessages([]);
    }
  };
  useEffect(() => {
    setBackgroundColor1("");
  }, [particularStudent || reply]);

  const item123 = [
    <Dropdown.Item onClick={() => HandleReply()}>Reply</Dropdown.Item>,
  ];
  let HandleReply = () => {
    setReply(true);
  };
  const renderIconButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<FaAngleDown />}
        circle
        color="white"
        size="10px"
      />
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenpoll = () => setOpenPoll(true);
  const handleClosepoll = () => {
    setOpenPoll(false);
  };
  const handleOpenpollView = () => setOpenPollView(true);
  const handleClosepollView = () => {
    setOpenPollView(false);
  };
  function stopPropagation(e) {
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }
  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };
  let handleImage = e => {
    handleOpen(true);
    openImagePreview(e);
  };

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  const [imageUrl, setImageUrl] = useState("");
  let [replyimgUrl, setReplyImageUrl] = useState("");

  useEffect(() => {
    const fetchUrl = async () => {
      if (
        message?.replayback &&
        Object.keys(message?.replayback).length > 0 &&
        message?.replayback?.file !== "" &&
        message?.replayback?.file != undefined
      ) {
        const fileData = await fetchImageUrl(
          message?.replayback?.file[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setReplyImageUrl(fileData);
        }
      }
    };

    fetchUrl();
  }, [message]);

  useEffect(() => {
    const fetchUrl = async () => {
      if (message?.messages?.files !== undefined) {
        const fileData = await fetchImageUrl(
          message?.messages?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }
      if (message?.status && message?.status?.message?.files != undefined) {
        const fileData = await fetchImageUrl(
          message?.status?.message?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }
    };

    fetchUrl();
  }, [message]);

  let handlePollAnswer = e => {
    async function fetchData() {
      setLoading(true);

      await AxiosInstance.get(`/employee/stu_getpoll?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let eee = x?.data?.voters[0]?.votefor?.map(x => x);

          let lll = data?.options?.map(outerOption => {
            return {
              ...outerOption,
              vote_type: data?.vote_type,
              isMatch:
                eee != undefined &&
                eee?.some(
                  voterOption =>
                    voterOption?.options_id === outerOption?.options_id
                ),
            };
          });

          let fff = lll.filter(x => {
            return x.isMatch === true;
          });
          setSelectedLabels(fff);
          setPollData(lll);
          setMainData(data);
          setLoading(false);
        })
        .catch(y => {
          setLoading(false);
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };

  let currentDate = new Date(); // Get the current date and time
  let year = currentDate.getFullYear(); // Get the year
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Get the month (add 1 because months are 0-based) and format it to have leading zeros if necessary
  let day = String(currentDate.getDate()).padStart(2, "0"); // Get the day of the month and format it with leading zeros if necessary
  let hours = String(currentDate.getHours()).padStart(2, "0"); // Get the hours and format them with leading zeros if necessary
  let minutes = String(currentDate.getMinutes()).padStart(2, "0"); // Get the minutes and format them with leading zeros if necessary

  let currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  // //(currentDateTime);
  let handlePollView = e => {
    // //(e);
    async function fetchData() {
      await AxiosInstance.get(`/employee/users_pollviewvotes?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let final = data?.polldata?.options;

          const updatedArray1 = final.filter(
            obj1 =>
              !data?.voters.some(obj2 => obj1.options_id === obj2.options_id)
          );
          const voterOptions = data?.voters?.map(voter => voter._id);

          // Filter updatedArray1 to remove objects with 'option' values that are also in voterOptions
          const filteredArray = updatedArray1.filter(
            obj => !voterOptions.includes(obj.options_id)
          );
          // Concatenate array2 to updatedArray1
          const finalArray = filteredArray.concat(data?.voters);
          // //(finalArray);
          // Update the state with the modified array
          setViewOptions(finalArray);
          setViewPollData(data);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };

  const handleLabelClick = user => {
    setSelectedLabels(prevLabels => {
      const labelIndex = prevLabels.findIndex(
        label => label.options_id === user.options_id
      );

      if (labelIndex !== -1) {
        return prevLabels.filter((_, index) => index !== labelIndex);
      } else {
        return [...prevLabels, user];
      }
    });
  };

  let handleLabelClickRadio = option => {
    setSelectedLabels([option]);
  };

  let HandleAnswerPoll = () => {
    if (selectedLabels.length === 0) {
      toast.error("Select any options", { position: "top-right" });
    } else {
      let payload = {
        pollid: maindata._id,
        userdata: {
          userid: user?._id,
          username: user?.username,
          number: user?.number,
          role: user?.role1,
          votefor: selectedLabels,
        },
      };
      async function students() {
        await AxiosInstance.post(`/employee/vote_poll`, payload, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then(x => {
            console?.log(x);
          })
          .catch(x => {
            console?.log(x);
            toast.error("There was an error", { position: "top-right" });
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            setOpenPoll(false);
          });
      }
      students();
    }
  };

  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    // extract your url by urlRegex something like
    const url = text.match(urlRegex);

    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }
    return (
      <Linkify componentDecorator={openInNewTab}>
        <p className={Styles.messageText}>
          {message?.messages?.text} <br />
        </p>
      </Linkify>
    );
  }
  let [selectedSymbol, setSelectedSymbol] = useState([]);
  const handleLikeDisslike = e => {
    console.log("first");
    setIsLiked(!isLiked);

    socket.emit(
      "share_like",
      {
        post_id: e?.share?.usershare_id,
        studentid: user?._id,
        username: user?.username,
        share: false,
        like: true,
        dislike: false,
      },
      function callback(msg) {
        console.log(msg);
        setSelectedSymbol(msg);
      }
    );
  };
  const handleDisslike = e => {
    setIsDisLiked(!isDisLiked);

    socket.emit(
      "share_like",
      {
        post_id: e?.share?.usershare_id,
        studentid: user?._id,
        username: user?.username,
        share: false,
        like: false,
        dislike: true,
      },
      function callback(msg) {
        // console.log(msg);
        setSelectedSymbol(msg);
      }
    );
  };
  const handleShare = e => {
    setIsModalOpen(true);
    setShareMsg(e);
    socket.emit(
      "share_like",
      {
        post_id: e?.share?.usershare_id,
        studentid: user?._id,
        username: user?.username,
        share: true,
        like: false,
        dislike: false,
      },
      function callback(msg) {
        // console.log(msg);
        setSelectedSymbol(msg);
      }
    );
  };
  const handleCloseShare = () => {
    setIsModalOpen(false); // Close modal
  };
  const shareOnWhatsApp = message => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  const shareOnFacebook = message => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  const shareViaEmail = message => {
    const mailto = `mailto:?subject=Check this out&body=${encodeURIComponent(
      message
    )}`;
    window.open(mailto);
  };
  return (
    <>
      <div
        className={
          message?.messages?.text !== ""
            ? `${Styles.Messageeee}`
            : `${Styles.Emptymsg}`
        }
      >
        <div
          className={
            own
              ? `${Styles.sendermsg}`
              : message?.sender === particularStudent?.trainersId
              ? `${Styles.recivermsg}`
              : `${Styles.displaynone}`
          }
        >
          {/* Material UI Modal for sharing options */}
          <MuiModal open={isModalOpen} onClose={handleCloseShare}>
            <Box sx={modalStyle}>
              <Typography variant="h6" component="h2">
                Share this message
              </Typography>
              <Typography sx={{ mt: 2 }}>{shareMsg?.messages?.text}</Typography>

              {/* Icon Buttons for sharing */}
              <Box sx={iconContainerStyle}>
                <MuiIconButton
                  onClick={() => shareOnWhatsApp(shareMsg?.messages?.text)}
                  style={{ background: "#25D366" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.82 9.82 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.23 8.23 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.2 8.2 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18s.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01"
                    ></path>
                  </svg>
                  {/* <FaWhatsapp size={40} color="#25D366" /> */}
                </MuiIconButton>
                <MuiIconButton
                  style={{ background: "#3b5998" }}
                  onClick={() => shareOnFacebook(shareMsg?.messages?.text)}
                >
                  {/* <FaFacebook size={40} color="#3b5998" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4z"
                    ></path>
                  </svg>
                </MuiIconButton>
                <MuiIconButton
                  style={{ background: "#BB001B" }}
                  onClick={() => shareViaEmail(shareMsg?.messages?.text)}
                >
                  {/* <FaEnvelope size={40} color="#BB001B" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M20 18h-2V9.25L12 13L6 9.25V18H4V6h1.2l6.8 4.25L18.8 6H20m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2"
                    ></path>
                  </svg>
                </MuiIconButton>
              </Box>

              {/* Close Button */}
              <Button
                onClick={handleCloseShare}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </Box>
          </MuiModal>
          <Modal
            onClose={handleClosepoll}
            // backdrop={backdrop}
            open={openPoll}
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header>
              <Modal.Title
                style={{
                  textAlign: "center",
                  color: "#f16f1d",
                  fontWeight: "bold",
                }}
              >
                Poll
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                overflow: "unset",
                maxheight: "none",
              }}
            >
              <aside className={Styles.MainContainer}>
                <h4 className={Styles.TextAlign} style={{ color: "green" }}>
                  {" "}
                  <small style={{ fontSize: "x-small", color: "black" }}>
                    Question:
                  </small>{" "}
                  {maindata?.poll_question}
                </h4>
                {maindata?.vote_type ? <span>Select one or more</span> : ""}
                {loading ? (
                  ""
                ) : (
                  <form>
                    {pollData?.map((ele, ind) => {
                      return (
                        <div
                          key={ele?.options_id}
                          className={Styles.polloption}
                        >
                          {" "}
                          {maindata?.vote_type === false ? (
                            <>
                              <input
                                type="radio"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => {
                                  handleLabelClickRadio(ele);
                                }}
                                className={Styles.TextAlign}
                              >
                                {ele?.option}
                              </label>
                            </>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => {
                                  handleLabelClick(ele);
                                }}
                                className={Styles.TextAlign}
                              >
                                {ele?.option}
                              </label>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </form>
                )}
              </aside>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={Styles.SubmitPoll}
                onClick={() => HandleAnswerPoll()}
              >
                <IoSendSharp />
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            onClose={handleClosepollView}
            // backdrop={backdrop}
            open={openPollView}
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header>
              <Modal.Title
                style={{
                  color: "#f16f1d",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Poll Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                overflow: "unset",
                maxheight: "none",
              }}
            >
              <div className={Styles.ViewPollBlock}>
                <h4 className={Styles.TextAlign} style={{ color: "green" }}>
                  <small style={{ fontSize: "x-small", color: "black" }}>
                    Question:
                  </small>{" "}
                  {viewPollData?.polldata?.poll_question}
                </h4>
                {viewOptions?.map((ele, ind) => {
                  return (
                    <aside className={Styles.options} key={ind}>
                      <div className={Styles.Voters}>
                        <h6 className={Styles.TextAlign}>{ele?.option}</h6>
                        {ele?.voters ? (
                          <span>Votes- {ele?.voters?.length}</span>
                        ) : (
                          <span>Votes- 0</span>
                        )}
                      </div>
                      {ele.voters ? (
                        <div>
                          {ele.voters.map((ele, ind) => {
                            return (
                              <p style={{ textTransform: "capitalize" }}>
                                {ele.username}
                              </p>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </aside>
                  );
                })}
              </div>
            </Modal.Body>
          </Modal>

          <MuiModal
            open={open}
            onClose={handleClose}
            aria-labelledby="image-modal"
            aria-describedby="image-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "100%",
                maxHeight: "100%",
                bgcolor: "background.paper",
                boxShadow: 24,
                outline: "none",
                overflow: "hidden",
                cursor: zoomLevel > 1 ? "grab" : "auto",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
            >
              <MuiIconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: "2222",
                }}
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={22}
                  height={22}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="crimson"
                    d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
                  ></path>
                </svg>
              </MuiIconButton>
              <img
                ref={imgRef}
                src={previewImage}
                alt="Modal Content"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  objectFit: "contain",
                  display: "block",
                  margin: "0 auto",
                  transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                  transition: "transform 0.3s ease",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  top: "35px",
                  right: "-3px",
                }}
              >
                <MuiIconButton variant="secondary" onClick={handleZoomOut}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#555"
                      d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M5 8v2h8V8z"
                    ></path>
                  </svg>
                </MuiIconButton>
                <MuiIconButton variant="secondary" onClick={handleZoomIn}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#555"
                      d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M8 5v3H5v2h3v3h2v-3h3V8h-3V5z"
                    ></path>
                  </svg>
                </MuiIconButton>
              </Box>
            </Box>
          </MuiModal>
          <main
            style={{ backgroundColor: backgroundColor1 }}
            onClick={handleClick}
          >
            <div>
              {message?.replayback &&
              Object.keys(message?.replayback).length > 0 ? (
                <div className={Styles.StatusText}>
                  Reply-{" "}
                  {message?.replayback?.message &&
                  message?.replayback?.message !== "" ? (
                    message?.replayback?.message?.length > 85 ? (
                      message?.replayback?.message.slice(0, 85)
                    ) : (
                      message?.replayback?.message
                    )
                  ) : message?.replayback?.file?.length > 0 ? (
                    <>
                      {(() => {
                        switch (message?.replayback?.file[0]?.mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <img
                                  src={replyimgUrl?.url}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                    display: "flex",
                                  }}
                                  onClick={e => {
                                    stopPropagation(e);
                                    handleImage(replyimgUrl?.url);
                                  }}
                                />{" "}
                              </>
                            );
                          default:
                            return (
                              <button className={Styles.btndownload1}>
                                {" "}
                                {message?.replayback?.file[0]?.originalname}
                              </button>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : message?.status ? (
                <div
                  className={Styles.StatusText}
                  style={{
                    wordBreak: "break-all",
                    textAlign: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  Status- &nbsp;
                  {message?.status?.message?.text ? (
                    message?.status?.message?.text?.slice(0, 85)
                  ) : message?.status?.message?.files?.length > 0 ? (
                    <>
                      {(() => {
                        switch (message?.status?.message?.files[0]?.mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <img
                                  src={imageUrl?.url}
                                  alt=""
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "5px",
                                    display: "flex",
                                  }}
                                />{" "}
                              </>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {message?.poll && message?.poll !== null ? (
              <div className={Styles.messageTop}>
                <div className={Styles.Pollhead}> poll</div>
                <h6 className={Styles.TextAlign}>
                  {message?.poll?.poll_question}
                </h6>

                <div>
                  {currentDateTime < message?.poll?.endTime ? (
                    <button
                      className={Styles.AnswerPoll}
                      onClick={e => {
                        stopPropagation(e);
                        handlePollAnswer(message?.poll?._id);
                        handleOpenpoll();
                      }}
                    >
                      Answer
                    </button>
                  ) : (
                    <p style={{ color: "red" }}>Poll Expired</p>
                  )}
                  {message?.poll?.stu_view_vote && (
                    <button
                      className={Styles.AnswerView}
                      onClick={e => {
                        stopPropagation(e);
                        handlePollView(message?.poll?._id);
                        handleOpenpollView();
                      }}
                    >
                      View
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className={Styles.messageTop}>
                {message?.messages?.text ? (
                  message?.messages?.text && message?.html ? (
                    // <pre
                    //   dangerouslySetInnerHTML={{
                    //     __html: message?.messages?.text,
                    //   }}
                    //   className={Styles.textEditStyle}
                    // />
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          href={decoratedHref}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtmlContent(
                            message?.messages?.text || ""
                          ),
                        }}
                        className={Styles.textEditStyle}
                      />
                    </Linkify>
                  ) : (
                    linkify(message?.messages?.text)
                  )
                ) : message?.messages?.files?.length > 0 ? (
                  <>
                    {(() => {
                      switch (message?.messages?.files[0].mimetype) {
                        case "image/jpg":
                        case "image/jpeg":
                        case "image/avif":
                        case "image/png":
                        case "image/webp":
                        case "image/tiff":
                        case "image/bmp":
                          return (
                            <>
                              <img
                                alt=""
                                src={imageUrl?.url}
                                style={{
                                  width: "200px",
                                  height: "130px",
                                  borderRadius: "5px",
                                  display: "flex",
                                }}
                                onClick={e => {
                                  stopPropagation(e);
                                  handleImage(imageUrl?.url);
                                }}
                              />

                              <button className={Styles.btndownload}>
                                <span
                                  className={Styles.Download}
                                  onClick={e => {
                                    stopPropagation(e);
                                    download(
                                      imageUrl?.url,
                                      message?.messages?.files[0]?.originalname,
                                      message?.messages?.files[0].filename
                                    );
                                  }}
                                >
                                  download
                                </span>
                              </button>
                            </>
                          );

                        case "application/docx":
                        case "application/doc":
                        case "application/msword":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                        case "application/vnd.ms-word.document.macroEnabled.12":
                        case "application/vnd.ms-excel":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                        case "application/vnd.ms-excel.sheet.macroEnabled.12":
                        case "application/vnd.ms-powerpoint":
                        case "application/xlsx":
                        case "application/pptx":
                        case "application/octet-stream":
                        case "text/plain":
                        case "text/html":
                        case "application/apk":
                        case "application/zip":
                        case "application/x-zip":
                        case "application/octet-stream":
                        case "application/x-zip-compressed":
                        case "application/pdf":
                        case "application/x-pdf":
                        case "application/x-gzpdf":
                        case "applications/vnd.pdf":
                        case "application/acrobat":
                        case "application/x-google-chrome-pdf":
                        case "text/pdf":
                        case "text/x-pdf":
                        case "text/csv":
                        case "application/json":
                          return (
                            <button
                              className={Styles.btndownload1}
                              disabled={isInProgress}
                            >
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                          );
                        case "audio/mpeg":
                        case "audio/mp3":
                        case "audio/webm":
                        case "application/wav":
                        case "video/webm":
                        case "audio/wav":
                          return (
                            <audio
                              src={imageUrl?.url}
                              controls
                              style={{ width: "250px", height: "40px" }}
                            >
                              <span className={Styles.Download}>
                                Click to download
                              </span>
                            </audio>
                          );
                        case "video/mp4":
                        case "video/x-matroska":
                          return (
                            <button
                              className={Styles.btndownload1}
                              disabled={isInProgress}
                            >
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                          );

                        default:
                          return (
                            <>
                              <button
                                className={Styles.btndownload1}
                                disabled={isInProgress}
                              >
                                {" "}
                                {message?.messages?.files[0]?.originalname}
                                <span
                                  className={Styles.Download}
                                  onClick={() =>
                                    download(
                                      imageUrl?.url,
                                      message?.messages?.files[0]?.originalname,
                                      message?.messages?.files[0].filename
                                    )
                                  }
                                >
                                  Click to download
                                </span>
                              </button>
                            </>
                          );
                      }
                    })()}
                  </>
                ) : (
                  <h1 className={Styles.Emptymsg}></h1>
                )}
              </div>
            )}
            <div className={Styles.messageBottom}>
              {Date.now ? (
                <Moment format="MMM DD - h:mm a">{message?.createdAt}</Moment>
              ) : (
                <Moment format="MMM DD YYYY h A">{message?.createdAt}</Moment>
              )}
              <span className={Styles.BatchGroup}>
                {message?.messages?.code}
              </span>
              {own ? (
                message?.tick == false ? (
                  <FcCheckmark />
                ) : (
                  <IoCheckmarkDoneSharp
                    style={{ color: " rgb(67, 160, 71)" }}
                  />
                )
              ) : (
                ""
              )}
              {!own &&
                (message?.share ? (
                  <div
                    style={{
                      gap: "3px",
                      display: "flex",
                      alignItems: "flex-end",
                      marginTop: "4px",
                    }}
                  >
                    {/* <Badge
                      showZero={false}
                      max={999}
                      badgeContent={likeCount(message?.share?.usershare_id)}
                      color="primary"
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: "x-small",
                          height: "15px",
                          minWidth: "15px",
                        },
                      }}
                    > */}
                    <div
                      style={{ padding: "3px" }}
                      onClick={e => {
                        handleLikeDisslike(message);
                        stopPropagation(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                      >
                        <path
                          // fill={
                          //   selectedSymbol && selectedSymbol?.like > 0
                          //     ? "blue"
                          //     : "white" ||
                          //       likeCount(message?.share?.usershare_id)
                          // }
                          fill={count > 0 ? "#1877F2" : "#fff"}
                          d="M23 10a2 2 0 0 0-2-2h-6.32l.96-4.57c.02-.1.03-.21.03-.32c0-.41-.17-.79-.44-1.06L14.17 1L7.59 7.58C7.22 7.95 7 8.45 7 9v10a2 2 0 0 0 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73zM1 21h4V9H1z"
                        ></path>
                      </svg>
                    </div>
                    {/* </Badge> */}

                    {/* <Badge
                      showZero={false}
                      max={999}
                      badgeContent={dislikeCount(message?.share?.usershare_id)}
                      color="primary"
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: "x-small",
                          height: "15px",
                          minWidth: "15px",
                        },
                      }}
                    > */}
                    <div
                      style={{ padding: "3px" }}
                      onClick={e => {
                        handleDisslike(message);
                        stopPropagation(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill={countDislikes > 0 ? "#1877F2" : "#fff"}
                          d="M19 15h4V3h-4m-4 0H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2a2 2 0 0 0 2 2h6.31l-.95 4.57c-.02.1-.03.2-.03.31c0 .42.17.79.44 1.06L9.83 23l6.58-6.59c.37-.36.59-.86.59-1.41V5a2 2 0 0 0-2-2"
                        ></path>
                      </svg>
                    </div>
                    {/* </Badge> */}

                    {/* <Badge
                      showZero={false}
                      max={999}
                      badgeContent={shareCount(message?.share?.usershare_id)}
                      color="primary"
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: "x-small",
                          height: "15px",
                          minWidth: "15px",
                        },
                      }}
                    > */}
                    <div
                      style={{ padding: "3px" }}
                      onClick={e => {
                        handleShare(message);
                        stopPropagation(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill={countShares > 0 ? "#1877F2" : "#fff"}
                          d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304c0 113.3 81.5 163.9 100.2 174.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5c-9.4-8.9-22.2-26.4-22.2-56.8c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144a31.76 31.76 0 0 0-34.4-5.4"
                        ></path>
                      </svg>
                    </div>
                    {/* </Badge> */}
                  </div>
                ) : (
                  ""
                ))}
              {backgroundColor1 === "rgb(0 0 0 / 12%)" ? (
                <span
                  className={Styles.ForwardMsgBlock}
                  onClick={e => stopPropagation(e)}
                >
                  {" "}
                  <Dropdown renderToggle={renderIconButton} placement="leftEnd">
                    {item123}
                  </Dropdown>
                </span>
              ) : (
                ""
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default StuMessage;
