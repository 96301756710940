import React, { useRef, useState } from "react";
import {
  Container,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Grid,
  Box,
  Autocomplete,
  RadioGroup,
  Radio,
  Dialog,
  IconButton,
  DialogContent,
  Paper,
  Avatar,
} from "@mui/material";
import html2canvas from "html2canvas";
import { styled } from "@mui/material/styles";
// import dayjs from "dayjs";
import { BddtAxios, BddtFile } from "../../api/AxiosInstance";
// import "./gotjob.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/gotjob.webp";
const CustomLabel = styled(Typography)({
  marginRight: "10px",
  color: "black",
  fontWeight: 500,
  alignSelf: "center", // Align center with TextField
});
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "50px 0 0 50px", // Semi-circle on the left side
    // backgroundColor: "#ff9800", // Orange background
    color: "#3c3f43",
    "& fieldset": {
      border: "none", // Remove border
    },
    "& input, & textarea": {
      color: "#3c3f43", //  text color
    },
  },
  "& label.Mui-focused": {
    color: "#3c3f43", // White label when focused
  },
});
const FieldsContainer = styled(Box)({
  backgroundColor: "#f8a055", // Orange background
  borderRadius: "65px 0 0 65px", // Semi-circle on the left side
  padding: "8px",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});
const GotJob = () => {
  const userToken = sessionStorage.getItem("token");
  let navigate = useNavigate();
  const [joiningDate, setJoiningDate] = useState(null);
  const [packagee, setPackage] = useState("");
  const [comment, setComment] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [expOtherComp, setExpOtherComp] = useState("");
  const [OtherCompName, setOtherCompName] = useState(null);
  const [externalComp, setExternalComp] = useState(false);
  const [internal, setInternal] = useState(false);
  const [OtherComp, setOtherComp] = useState(false);
  const [OtherCompExp, setOtherCompExp] = useState(false);
  const [OtherDesignation, setOtherDesignation] = useState(false);
  const [otherDesignationVal, setOtherDesignationVal] = useState(null);
  const [experienced, setExperienced] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [jobType, setJobType] = useState("");
  const [writenTesti, setWritenTesti] = useState("");
  const [googlrUrl, setGoogleUrl] = useState("");
  const [imageBlob, setImageBlob] = useState(null);
  const [errors, setErrors] = useState({});

  //form to image
  const canvasRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    package: "",
    qualification: "",
    degreePercentage: "",
    stream: "",
    yearOfPassing: "",
    collegeName: "",
    collegeCity: "",
  });
  const [trainerFeedback, setTrainerFeedback] = useState("");
  const [collegeFeedback, setCollegeFeedback] = useState("");
  const [generalFeedback, setGeneralFeedback] = useState("");

  const [image, setImage] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  // Handler for signature image upload
  const handleSignatureUpload = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignatureImage(reader.result); // Store the base64 image data
      };
      reader.readAsDataURL(file); // Read file as base64 data
    }
  };

  // Trigger file input click programmatically
  const handleSignatureClick = () => {
    document.getElementById("signature-upload").click();
  };
  const handleImageChangeTest = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitImage = e => {
    e.preventDefault();

    const formElement = document.getElementById("form-to-image");

    html2canvas(formElement).then(canvas => {
      // Convert the canvas to a Blob (binary format)
      canvas.toBlob(blob => {
        // Store the Blob in state
        setImageBlob(blob);

        // Log the blob or use it where necessary
        console.log(blob);

        // Optional: Download the image if needed
        // const link = document.createElement("a");
        // const url = URL.createObjectURL(blob);
        // link.href = url;
        // link.download = "form-image.png";
        // link.click();

        // Revoke the object URL after the download
        // URL.revokeObjectURL(url);
      }, "image/png");
    });
  };

  const downloadImage = () => {
    console.log("download");
    const canvas = canvasRef.current;
    const image = canvas.toDataURL("image/png"); // Gets image in PNG format
    console.log(image); // Log the image data to inspect

    // Check if the image is in the correct format
    if (image.startsWith("data:image/png")) {
      const link = document.createElement("a");
      link.href = image;
      link.download = "filled-form.png";
      link.click();
    } else {
      console.error("The image is not in PNG format");
    }
  };

  const [imageFile, setImageFile] = useState(null); // Image file
  const [videoFile, setVideoFile] = useState(null); // Video file
  //preview
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [openPreview, setOpenPreview] = useState(false); // Modal state
  const [previewType, setPreviewType] = useState("");
  console.log(videoFile);
  // Open Modal to Preview
  const handleOpenPreview = type => {
    setPreviewType(type);
    setOpenPreview(true);
  };
  // Close Modal
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleCancel = () => {
    // Handle form cancellation
  };

  const handleImageChange = event => {
    setImageFileName(event.target.files[0]?.name || "");
  };

  const handleVideoChange = event => {
    setVideoFileName(event.target.files[0]?.name || "");
    setVideoFile(event.target.files[0]);
  };
  //testimonial Modal
  const [showModal, setShowModal] = useState(false);

  // Functions to show and hide the modal
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleOtherCompChange = event => {
    setOtherComp(event.target.checked);
  };

  const handleOtherCompChangeExp = event => {
    setOtherCompExp(event.target.checked);
  };
  const handleCheckboxChange = value => {
    setSelectedOption(prev => (prev === value ? "" : value));
  };
  const handleJobTypeChange = event => {
    const selectedJobType = event.target.value;
    setJobType(event.target.value);
    // Update states based on selection
    if (selectedJobType === "external") {
      setExternalComp(true);
      setInternal(false);
    } else if (selectedJobType === "internal") {
      setInternal(true);
      setExternalComp(false);
    }
  };

  const handleVideoUpload = event => {
    setVideoFile(event.target.files[0]);
  };

  // get api starts here
  // Simulate API call to fetch designations
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const fetchDesignations = async () => {
    try {
      const { data } = await BddtAxios.get(
        `/commons/designation/?token=${userToken}`
      ); // Replace with your API endpoint
      console.log(data.results);
      setDesignations(data.results);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };
  const handleDesignationChange = (event, newValue) => {
    // if (newValue) {
    //   setSelectedDesignation(newValue.id);
    // } else {
    //   setSelectedDesignation(null);
    // }
    if (newValue) {
      setErrors(prev => ({ ...prev, designation: null }));
      setSelectedDesignation(newValue.id);
    } else {
      setSelectedDesignation(null);
      setErrors(prev => ({
        ...prev,
        designation: "Designation is required.",
      }));
    }
  };

  // Simulate API call to fetch location
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const fetchLocations = async () => {
    try {
      const { data } = await BddtAxios.get(
        `/commons/cities/?token=${userToken}`
      ); // Replace with your API endpoint
      console.log(data.results);
      setLocations(data.results);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };
  const handleLocationChange = (event, newValue) => {
    if (newValue) {
      setSelectedLocation(newValue.id);
      setErrors(prev => ({ ...prev, location: null }));
    } else {
      setSelectedLocation(null);
      setErrors(prev => ({
        ...prev,
        location: "Location is required.",
      }));
    }
  };
  // Simulate API call to fetch Branch
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const fetchBranches = async () => {
    try {
      const { data } = await BddtAxios.get(
        `/branches/branchmini/?token=${userToken}&&st_branch="true"`
      ); // Replace with your API endpoint
      console.log(data.results);
      setBranches(data.results);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };
  const handleBranchChange = (event, newValue) => {
    if (newValue) {
      setSelectedBranch(newValue.id);
      setErrors(prev => ({ ...prev, branch: null }));
    } else {
      setSelectedBranch(null);
      setErrors(prev => ({
        ...prev,
        branch: "Branch is required.",
      }));
    }
  };

  // Simulate API call to fetch all requirement
  const [requirements, setRequirements] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const fetchRequirements = async () => {
    try {
      const { data } = await BddtAxios.get(
        `/hr/req-hr-mini/?token=${userToken}`
      ); // Replace with your API endpoint
      console.log(data.results);
      setRequirements(data.results);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleRequirementChange = (event, newValue) => {
    if (newValue) {
      setSelectedRequirement(newValue.id);
      setErrors(prev => ({ ...prev, requirement: null }));
    } else {
      setSelectedRequirement(null);
      setErrors(prev => ({
        ...prev,
        requirement: "All Requiremnt is required.",
      }));
    }
  };

  // Simulate API call to fetch company
  const [companys, setCompanys] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const fetchCompanys = async () => {
    try {
      const { data } = await BddtAxios.get(
        `/familiar/companymin/?token=${userToken}`
      ); // Replace with your API endpoint
      console.log(data.results);
      setCompanys(data.results);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };
  const handleCompanyChange = (event, newValue) => {
    if (newValue) {
      setSelectedCompany(newValue.id);
      setErrors(prev => ({ ...prev, company: null }));
    } else {
      setSelectedCompany(null);
      setErrors(prev => ({
        ...prev,
        company: "company is required.",
      }));
    }
  };
  const handleJoiningDate = event => {
    console.log(event);
    if (event) {
      setJoiningDate(event.target.value);
      setErrors(prev => ({ ...prev, joiningDate: null }));
    } else {
      setSelectedCompany(null); // Clear the selection
      setErrors(prev => ({
        ...prev,
        joiningDate: "joiningDate is required.",
      }));
    }
  };
  const handlewrittenTestimonial = e => {
    console.log(e);
    if (e) {
      setWritenTesti(e.target.value);
      setErrors(prev => ({ ...prev, writenTesti: null }));
    } else {
      setWritenTesti(null); // Clear the selection
      setErrors(prev => ({
        ...prev,
        writenTesti: "Written Testiminial is required.",
      }));
    }
  };
  const handleOtherCompany = e => {
    if (e) {
      setOtherCompName(e.target.value);
      setErrors(prev => ({ ...prev, othercompany: null }));
    } else {
      setOtherCompName(null); // Clear the selection
      setErrors(prev => ({
        ...prev,
        othercompany: "Fresher Other Company Name is required.",
      }));
    }
  };
  // Validate form fields
  const validateForm = () => {
    const newErrors = {};

    if (externalComp && !selectedCompany)
      newErrors.company = "Company is required.";
    if (externalComp && OtherComp && !OtherCompName)
      newErrors.othercompany = "Fresher Other Company Name is required.";
    if (internal && !selectedRequirement)
      newErrors.requirement = "All Requiremnt is required.";

    if (!selectedDesignation)
      newErrors.designation = "Designation is required.";
    if (!selectedLocation) newErrors.location = "Location is required.";

    if (!joiningDate) newErrors.joiningDate = "Joining Date is required.";
    if (!selectedBranch) newErrors.branch = "Branch is required.";

    if (!writenTesti)
      newErrors.writenTesti = "Your experience in this institute is required.";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  // Handle form submission
  const handleSubmit = async event => {
    event.preventDefault();
    if (!externalComp && !internal && !experienced) {
      toast.error("select any gotjob options");
    } else if (experienced && !internal && !externalComp) {
      toast.error("select any gotjob options");
    } else {
      if (!validateForm()) {
        return; // If validation fails, stop submission
      }

      const formData = new FormData();

      let data = {
        external: externalComp,
        req: selectedRequirement,
        location: selectedLocation,
        joining_date: joiningDate,
        joining_status: 1,
        package: packagee,
        company: selectedCompany,
        other_company: OtherComp,
        external_company: OtherCompName,
        role: selectedDesignation,
        other_role: OtherDesignation,
        otherrole: otherDesignationVal,
        comment: comment,
        internal: internal,
        direct: internal === true ? true : false,
        branch_id: selectedBranch,
        experienced: experienced,
      };
      let url = {
        google_review: {
          1: {
            url: googlrUrl,
            not_interested: {},
            delete: "false",
            comment: "",
          },
        },
      };
      let img = {
        google_review_image: {
          1: {
            img: "photo1",
            comment: "",
          },
        },
        video_with_company_name: {
          1: {
            img: "photo2",
            comment: "",
          },
        },
      };
      formData.append("token", userToken);
      formData.append("student", JSON.stringify(data));
      formData.append("url_details", JSON.stringify(url));
      formData.append("img_details", JSON.stringify(img));
      formData.append("written_testimonial", writenTesti);
      formData.append("photo1", imageBlob);
      formData.append("photo2", videoFile);

      try {
        const response = await BddtFile.post(
          `/testimonialcollector/testimonial-post/`,
          formData,
          {
            headers: {
              // Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then(x => {
            toast.success("Form submitted successfully");
            navigate("/student-dashboard");
          })
          .catch(y => {
            toast.error(y?.response?.data?.message || "somthing went wrong");
          });

        // Handle success
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      {/* Modal for Image/Video Preview */}
      <Dialog
        open={openPreview}
        onClose={handleClosePreview}
        maxWidth="md"
        fullWidth
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleClosePreview}
        >
          <svg width={18.54} height={20} viewBox="0 0 1216 1312">
            <path
              fill="crimson"
              d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
            ></path>
          </svg>
        </IconButton>
        <DialogContent sx={{ textAlign: "center" }}>
          {previewType === "image" && imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          )}
          {previewType === "video" && videoPreview && (
            <video controls style={{ maxWidth: "100%", height: "auto" }}>
              <source src={videoPreview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog>
      {/* Modal for Testimonial Form */}
      <Dialog open={showModal} onClose={handleClose} maxWidth="md" fullWidth>
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleClose}
        >
          <svg width={18.54} height={20} viewBox="0 0 1216 1312">
            <path
              fill="crimson"
              d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
            ></path>
          </svg>
        </IconButton>
        <DialogContent sx={{ textAlign: "center" }}>
          <form onSubmit={handleSubmitImage} id="form-to-image">
            <Paper
              elevation={3}
              style={{
                width: "210mm",
                height: "297mm",
                padding: "20px 40px",
                margin: "20px auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "30px", // Space below the heading
                  padding: "10px 0", // Padding for spacing around the header
                }}
              >
                {/* Left-aligned logo */}
                <Avatar src={logo} sx={{ width: 80, height: 80 }} alt="Logo" />

                {/* Centered heading */}
                <Box sx={{ textAlign: "center", flexGrow: 1 }}>
                  <Typography
                    variant="h4"
                    sx={{ color: "#000", fontWeight: 600 }}
                  >
                    Testimonial Form
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#000", fontStyle: "italic" }}
                  >
                    Please provide your feedback below
                  </Typography>
                </Box>

                {/* Empty Box for Spacing or Future Use */}
                <Box sx={{ width: 60 }}></Box>
              </Box>
              <Grid
                style={{
                  // backgroundColor: "#f1f1f1",
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "30px",
                }}
              >
                {" "}
                <Box style={{ flex: 1, paddingRight: "10px" }}>
                  {" "}
                  {/* Less padding for better fit */}
                  <FieldsContainer>
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                        }}
                      >
                        <CustomLabel>Name</CustomLabel>
                        <CustomTextField
                          label=""
                          variant="standard"
                          name="name"
                          fullWidth
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <CustomLabel>Designation</CustomLabel>
                        <CustomTextField
                          label=""
                          variant="standard"
                          fullWidth
                          name="designation"
                          value={formData.designation}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                          paddingLeft: "20px",
                        }}
                      >
                        <CustomLabel>Package</CustomLabel>
                        <CustomTextField
                          label=""
                          variant="standard"
                          fullWidth
                          name="package"
                          value={formData.package}
                          onChange={handleChange}
                        />
                      </Box>
                    </>
                  </FieldsContainer>
                </Box>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "140px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      accept="image/*"
                      type="file"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={handleImageChangeTest}
                    />
                    <label htmlFor="upload-button">
                      <Avatar
                        src={image}
                        sx={{
                          width: 170, // Smaller size to fit the A4 layout
                          height: 170,
                          border: "5px solid #f8a055",
                          position: "absolute",
                          top: "-11px",
                          right: "-32px",
                        }}
                        alt="Profile Image"
                      />
                    </label>
                  </div>
                </Grid>
              </Grid>
              <Grid>
                {" "}
                <Box
                  style={{
                    flex: 1,
                    paddingRight: "10px",
                    marginBottom: "30px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      {" "}
                      {/* Add margin to separate fields */}
                      <CustomLabel>Qualification</CustomLabel>
                      <CustomTextField
                        label=""
                        variant="standard"
                        fullWidth
                        name="qualification"
                        value={formData.qualification}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      {" "}
                      <CustomLabel>Degree</CustomLabel>
                      <CustomTextField
                        label=""
                        variant="standard"
                        fullWidth
                        name="degreePercentage"
                        value={formData.degreePercentage}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      {" "}
                      {/* Add margin to separate fields */}
                      <CustomLabel>Stream</CustomLabel>
                      <CustomTextField
                        label=""
                        variant="standard"
                        fullWidth
                        name="stream"
                        value={formData.stream}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      {" "}
                      <CustomLabel>Y.O.P</CustomLabel>
                      <CustomTextField
                        label=""
                        variant="standard"
                        fullWidth
                        name="yearOfPassing"
                        value={formData.yearOfPassing}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <CustomLabel
                      style={{ textAlign: "start", paddingLeft: "10px" }}
                    >
                      College Name
                    </CustomLabel>
                    <CustomTextField
                      label=""
                      variant="standard"
                      fullWidth
                      name="collegeName"
                      value={formData.collegeName}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      {" "}
                      {/* Add margin to separate fields */}
                      <CustomLabel
                        style={{ textAlign: "start", paddingLeft: "10px" }}
                      >
                        College City
                      </CustomLabel>
                      <CustomTextField
                        label=""
                        variant="standard"
                        fullWidth
                        name="collegeCity"
                        value={formData.collegeCity}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      <CustomLabel>Student Signature</CustomLabel>

                      {/* Hidden file input for signature */}
                      <input
                        accept="image/*"
                        type="file"
                        id="signature-upload"
                        style={{ display: "none" }} // Hide the file input
                        onChange={handleSignatureUpload} // Handle signature image upload
                      />

                      {/* Clickable area to upload signature */}
                      <div
                        style={{
                          marginLeft: "20px",
                          border: "2px dashed #f8a055", // Dashed border for visual cue
                          borderRadius: "10px",
                          width: 170, // Width similar to profile image
                          height: 100, // Height for signature
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={handleSignatureClick} // Click event to trigger file input
                      >
                        {signatureImage ? (
                          <Avatar
                            src={signatureImage} // Show the signature image if uploaded
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px", // Keep border-radius for signature area
                            }}
                            alt="Signature Preview"
                          />
                        ) : (
                          <Typography variant="caption" color="textSecondary">
                            Upload Signature
                          </Typography>
                        )}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Box
                style={{
                  flex: 1,
                  border: "5px solid #f8a055", // Border with 5px thickness
                  padding: "20px", // Padding inside the box
                  borderRadius: "8px", // Optional: Border radius for rounded corners
                  marginTop: "20px", // Space above the feedback section
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Feedback Section
                </Typography>

                {/* Trainer Feedback */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <CustomLabel>Training Experience</CustomLabel>
                  <CustomTextField
                    label=""
                    variant="standard"
                    multiline
                    rows={7} // Set rows for a larger text area
                    fullWidth
                    value={trainerFeedback}
                    onChange={e => setTrainerFeedback(e.target.value)}
                  />
                  {/* <CustomTextField label="" variant="standard" fullWidth /> */}
                </Box>

                {/* College Feedback */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <CustomLabel>Mock & Presentation Experience</CustomLabel>
                  <CustomTextField
                    label=""
                    variant="standard"
                    multiline
                    rows={4} // Set rows for a larger text area
                    fullWidth
                    value={collegeFeedback}
                    onChange={e => setCollegeFeedback(e.target.value)} // Capture input
                  />
                </Box>

                {/* General Feedback */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <CustomLabel>Grooming & Placement Experience</CustomLabel>
                  <CustomTextField
                    label=""
                    variant="standard"
                    multiline
                    rows={4} // Set rows for a larger text area
                    fullWidth
                    value={generalFeedback}
                    onChange={e => setGeneralFeedback(e.target.value)} // Capture input
                  />
                </Box>
              </Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#f8a055",
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  borderRadius: "25px",
                  marginTop: "10px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#e07b30", // Darker shade of orange on hover
                  },
                  "&:active": {
                    backgroundColor: "#ca6925", // Even darker when the button is pressed
                  },
                }}
                onClick={handleClose}
              >
                Done
              </Button>
            </Paper>
          </form>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          padding: 3,
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          maxWidth: "100%",
          margin: "auto",
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          sx={{
            fontWeight: "bold",
            color: "#333",
            marginBottom: 2,
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        >
          Got Job
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Heading for Checkboxes */}

            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Grid container spacing={1}>
                  {selectedOption != "experienced" && (
                    <>
                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOption === "org"}
                              sx={{ color: "#f16f1d" }}
                              onChange={event => {
                                handleCheckboxChange("org");
                                setInternal(event.target.checked);
                              }}
                            />
                          }
                          label="Got job from our organization"
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOption === "external"}
                              sx={{ color: "#f16f1d" }}
                              onChange={event => {
                                setExternalComp(event.target.checked);
                                handleCheckboxChange("external");
                              }}
                            />
                          }
                          label="Got job from outside requirement"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOption === "experienced"}
                          sx={{ color: "#f16f1d" }}
                          onChange={event => {
                            handleCheckboxChange("experienced");
                            setExperienced(event.target.checked);
                          }}
                        />
                      }
                      label="Experienced"
                    />
                  </Grid>
                  {selectedOption === "experienced" && (
                    <Grid item xs={12} sm={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="job-type"
                          name="jobType"
                          value={jobType}
                          onChange={handleJobTypeChange}
                          row
                        >
                          <FormControlLabel
                            value="internal"
                            control={<Radio sx={{ color: "#f16f1d" }} />}
                            label="Got job from our organization"
                          />

                          <FormControlLabel
                            value="external"
                            control={<Radio sx={{ color: "#f16f1d" }} />}
                            label="Got job from outside requirement"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </FormControl>
            </Grid>
            {selectedOption === "external" ? (
              <Grid item xs={12} sm={4}>
                {OtherComp ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        marginBottom: 1,
                      }}
                    >
                      Fresher Other Company Name:
                    </Typography>
                    <TextField
                      label="Other Company Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={OtherCompName}
                      // onChange={e => setOtherCompName(e.target.value)}
                      onChange={handleOtherCompany}
                      InputLabelProps={{
                        sx: { fontSize: "0.9rem", color: "#f16f1d" },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#f16f1d" },
                          "&:hover fieldset": { borderColor: "#ff8c4a" },
                          "&.Mui-focused fieldset": { borderColor: "#f16f1d" },
                        },
                      }}
                      error={!!errors.othercompany}
                      helperText={errors.othercompany}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        marginBottom: 1,
                      }}
                    >
                      Company:
                    </Typography>
                    <Autocomplete
                      options={companys}
                      getOptionLabel={option => option.name}
                      onChange={handleCompanyChange}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Company"
                          variant="outlined"
                          fullWidth
                          size="small"
                          InputLabelProps={{
                            sx: { fontSize: "0.9rem", color: "#f16f1d" },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { borderColor: "#f16f1d" },
                              "&:hover fieldset": { borderColor: "#ff8c4a" },
                              "&.Mui-focused fieldset": {
                                borderColor: "#f16f1d",
                              },
                            },
                          }}
                          onFocus={fetchCompanys}
                          error={!!errors.company}
                          helperText={errors.company}
                        />
                      )}
                    />
                  </>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox sx={{ color: "#f16f1d" }} />}
                    label="Other Company"
                    onChange={handleOtherCompChange}
                  />
                </Grid>
              </Grid>
            ) : selectedOption === "org" ? (
              <Grid item xs={12} sm={4}>
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: 1,
                    }}
                  >
                    All Requirement:
                  </Typography>
                  <Autocomplete
                    options={requirements}
                    getOptionLabel={option => option.requirement_name}
                    onChange={handleRequirementChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Requiremnt"
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          sx: { fontSize: "0.9rem", color: "#f16f1d" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#f16f1d" },
                            "&:hover fieldset": { borderColor: "#ff8c4a" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#f16f1d",
                            },
                          },
                        }}
                        onFocus={fetchRequirements}
                        error={!!errors.requirement}
                        helperText={errors.requirement}
                      />
                    )}
                  />
                </>
              </Grid>
            ) : selectedOption === "experienced" && jobType === "external" ? (
              <Grid item xs={12} sm={4}>
                {OtherCompExp ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        marginBottom: 1,
                      }}
                    >
                      Other Company Name:
                    </Typography>
                    <TextField
                      label="Other company name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={expOtherComp}
                      onChange={e => setExpOtherComp(e.target.value)}
                      InputLabelProps={{
                        sx: { fontSize: "0.9rem", color: "#f16f1d" },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#f16f1d" },
                          "&:hover fieldset": { borderColor: "#ff8c4a" },
                          "&.Mui-focused fieldset": { borderColor: "#f16f1d" },
                        },
                      }}
                      error={!!errors.othercompany}
                      helperText={errors.othercompany}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        marginBottom: 1,
                      }}
                    >
                      Company:
                    </Typography>
                    <Autocomplete
                      options={companys}
                      getOptionLabel={option => option.name}
                      onChange={handleCompanyChange}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Company"
                          variant="outlined"
                          fullWidth
                          size="small"
                          InputLabelProps={{
                            sx: { fontSize: "0.9rem", color: "#f16f1d" },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { borderColor: "#f16f1d" },
                              "&:hover fieldset": { borderColor: "#ff8c4a" },
                              "&.Mui-focused fieldset": {
                                borderColor: "#f16f1d",
                              },
                            },
                          }}
                          onFocus={fetchCompanys}
                          error={!!errors.company}
                          helperText={errors.company}
                        />
                      )}
                    />
                  </>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox sx={{ color: "#f16f1d" }} />}
                    label="Other Company"
                    onChange={handleOtherCompChangeExp}
                  />
                </Grid>
              </Grid>
            ) : selectedOption === "experienced" && jobType === "internal" ? (
              <Grid item xs={12} sm={4}>
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: 1,
                    }}
                  >
                    All Requirement:
                  </Typography>
                  <Autocomplete
                    options={requirements}
                    getOptionLabel={option => option.requirement_name}
                    onChange={handleRequirementChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Requiremnt"
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          sx: { fontSize: "0.9rem", color: "#f16f1d" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#f16f1d" },
                            "&:hover fieldset": { borderColor: "#ff8c4a" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#f16f1d",
                            },
                          },
                        }}
                        onFocus={fetchRequirements}
                        error={!!errors.requirement}
                        helperText={errors.requirement}
                      />
                    )}
                  />
                </>
              </Grid>
            ) : (
              ""
            )}
            {/* Designation Autoselect */}

            <Grid container item spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
                >
                  Designation:
                </Typography>

                {!OtherDesignation ? (
                  <Autocomplete
                    options={designations}
                    getOptionLabel={option => option.name}
                    onChange={handleDesignationChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Designation"
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          sx: { fontSize: "0.9rem", color: "#f16f1d" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#f16f1d" },
                            "&:hover fieldset": { borderColor: "#ff8c4a" },
                            "&.Mui-focused fieldset": {
                              borderColor: "#f16f1d",
                            },
                          },
                        }}
                        onFocus={fetchDesignations} // API call on focus
                        error={!!errors.designation}
                        helperText={errors.designation}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    label="Other Designation"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={otherDesignationVal}
                    onChange={e => setOtherDesignationVal(e.target.value)}
                    InputLabelProps={{
                      sx: { fontSize: "0.9rem", color: "#f16f1d" },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#f16f1d" },
                        "&:hover fieldset": { borderColor: "#ff8c4a" },
                        "&.Mui-focused fieldset": { borderColor: "#f16f1d" },
                      },
                    }}
                  />
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox sx={{ color: "#f16f1d" }} />}
                    label="Other designation"
                    onChange={event =>
                      setOtherDesignation(event.target.checked)
                    }
                  />
                </Grid>
              </Grid>

              {/* Location Field */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
                >
                  Location:
                </Typography>
                <Autocomplete
                  options={locations}
                  getOptionLabel={option => option.name}
                  onChange={handleLocationChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Location"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        sx: { fontSize: "0.9rem", color: "#f16f1d" },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#f16f1d" },
                          "&:hover fieldset": { borderColor: "#ff8c4a" },
                          "&.Mui-focused fieldset": { borderColor: "#f16f1d" },
                        },
                      }}
                      onFocus={fetchLocations}
                      error={!!errors.location}
                      helperText={errors.location}
                    />
                  )}
                />
              </Grid>

              {/* Joining Date Field */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
                >
                  Joining Date:
                </Typography>

                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="date"
                  // onChange={e => setJoiningDate(e.target.value)}
                  onChange={handleJoiningDate}
                  InputLabelProps={{
                    sx: {
                      color: "#f16f1d",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#f16f1d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#ff8c4a",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#f16f1d",
                      },
                    },
                  }}
                  error={!!errors.joiningDate}
                  helperText={errors.joiningDate}
                />
              </Grid>
            </Grid>

            {/* Placement and Comment Fields */}
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
                >
                  Branch:
                </Typography>
                <Autocomplete
                  options={branches}
                  getOptionLabel={option => option.name}
                  onChange={handleBranchChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Location"
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        sx: { fontSize: "0.9rem", color: "#f16f1d" },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#f16f1d" },
                          "&:hover fieldset": { borderColor: "#ff8c4a" },
                          "&.Mui-focused fieldset": { borderColor: "#f16f1d" },
                        },
                      }}
                      onFocus={fetchBranches}
                      error={!!errors.branch}
                      helperText={errors.branch}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
                >
                  Package:
                </Typography>
                <TextField
                  label="package"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={packagee}
                  onChange={e => setPackage(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: "0.9rem", color: "#f16f1d" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#f16f1d" },
                      "&:hover fieldset": { borderColor: "#ff8c4a" },
                      "&.Mui-focused fieldset": { borderColor: "#f16f1d" },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
                >
                  Comment:
                </Typography>
                <TextField
                  label="Comment"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  rows={1}
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: "0.9rem", color: "#f16f1d" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#f16f1d" },
                      "&:hover fieldset": { borderColor: "#ff8c4a" },
                      "&.Mui-focused fieldset": { borderColor: "#f16f1d" },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
              >
                Your experience in this institute:
              </Typography>
              <TextField
                label="few words about your experience"
                variant="outlined"
                fullWidth
                size="small"
                value={writenTesti}
                // onChange={e => setWritenTesti(e.target.value)}
                onChange={handlewrittenTestimonial}
                InputLabelProps={{
                  sx: {
                    color: "#f16f1d",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#f16f1d",
                    },
                    "&:hover fieldset": {
                      borderColor: "#ff8c4a",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#f16f1d",
                    },
                  },
                }}
                error={!!errors.writenTesti}
                helperText={errors.writenTesti}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
              >
                Google review image:
              </Typography>
              <TextField
                label="Image url"
                variant="outlined"
                size="small"
                fullWidth
                value={googlrUrl}
                onChange={e => setGoogleUrl(e.target.value)}
                InputLabelProps={{
                  sx: {
                    color: "#f16f1d",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#f16f1d",
                    },
                    "&:hover fieldset": {
                      borderColor: "#ff8c4a",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#f16f1d",
                    },
                  },
                }}
              />
            </Grid>
            {/* <Grid>
              {imageBlob && <img src={URL.createObjectURL(imageBlob)} alt="" />}
            </Grid> */}
            {/* File Uploads Section */}
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: 1 }}
              >
                Written Testimonial image:
              </Typography>

              <Grid container spacing={2}>
                {/* Image Upload */}
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <img
                      src="https://testchatb.qspiders.com/public/uploads/thumbnail-Image_20240923_130431.jpeg"
                      alt=""
                      width={100}
                      height={100}
                      style={{
                        margin: "5px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        handleOpenPreview("image");
                        setImagePreview(
                          "https://testchatb.qspiders.com/public/uploads/Image_20240923_130431-1727080916512.jpeg"
                        );
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        backgroundColor: "#f16f1d",
                        color: "#fff",
                        fontSize: "0.75rem",
                        padding: "6px 12px",
                        "&:hover": { backgroundColor: "#ff8c4a" },
                      }}
                      onClick={handleShow}
                    >
                      Testimonial form
                    </Button>
                  </Box>
                </Grid>

                {/* Video Upload */}
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <img
                      src={`https://testchatb.qspiders.com/public/uploads/thumbnail-Image_20240923_130431.jpeg`}
                      width={100}
                      height={100}
                      style={{
                        margin: "5px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        handleOpenPreview("video");
                        setVideoPreview(
                          "http://media.w3.org/2010/05/sintel/trailer.mp4"
                        );
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        backgroundColor: "#f16f1d",
                        color: "#fff",
                        fontSize: "0.75rem",
                        padding: "6px 12px",
                        "&:hover": { backgroundColor: "#ff8c4a" },
                      }}
                    >
                      Upload Video
                      <input
                        type="file"
                        accept="video/*"
                        hidden
                        onChange={handleVideoChange}
                      />
                    </Button>
                    <Typography variant="body2" sx={{ color: "#666" }}>
                      {videoFileName || "No file selected"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Submit and Cancel Buttons */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#f16f1d",
                    color: "#fff",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#ff8c4a",
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  fullWidth
                  onClick={handleCancel}
                  sx={{
                    borderColor: "#f16f1d",
                    color: "#f16f1d",
                    "&:hover": {
                      backgroundColor: "#ffe3d5",
                      borderColor: "#f16f1d",
                    },
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default GotJob;
